import Point from "./point";
import { LatLng } from "leaflet";

export enum ActivityType {
    Unknown = "Unknown",
    Run = "Run",
    Ride = "Ride",
    Walk = "Walk"
}

export class Duration {
    moving_time?: number;
    elapsed_time?: number;
}

export class Location {
    start: Point = new Point();
    end: Point = new Point();
}

export class ActivityStyle {
    color?: string;
    weight?: number;
}

export default class Activity {

    id?: number;

    name?: string;

    type?: ActivityType;

    duration: Duration = new Duration();

    location: Location = new Location();

    encoded_polyline?: string;

    distance: number = 0;

    date?: Date;

    positions: [number, number][] = [];

    decodePolyline(decoder: any) {
        this.positions = decoder.decode(this.encoded_polyline);
    }


    calculateCenterPosition(): LatLng {
        let lat = 0;
        let lng = 0;

        this.positions.forEach((position: [number, number]) => {
            lat += position[0];
            lng += position[1];
        });

        lat /= this.positions.length;
        lng /= this.positions.length;

        return new LatLng(lat, lng);
    }

    isInTallinn(): boolean {
        const start = this.location.start;
        if (start.lat === undefined || start.lng === undefined) {
            return false;
        }

        return start.lat > 59.3 && start.lat < 59.6 &&
            start.lng > 24.5 && start.lng < 25.2;
    }

    isInYear(year: number): boolean {
        return this.date !== undefined && this.date.getFullYear() === year;
    }

    isIn2021() { return this.isInYear(2021); }

    isIn2022(): boolean { return this.isInYear(2022); }

    isIn2023(): boolean { return this.isInYear(2023); }

    isIn2024(): boolean { return this.isInYear(2024);}

    isBefore11September2022(): boolean {
        return this.isBefore(new Date(2022, 8, 11));
    }

    /**
     * Approx. date when new shoes were bought
     */
    isAfter20December2023(): boolean {
        return this.isAfter(new Date(2023, 11, 20));
    }

    isBefore(date: Date): boolean {
        return this.date !== undefined && this.date < date;
    }

    isAfter(date: Date): boolean {
        return this.date !== undefined && this.date > date;
    }

    static fromJson(json: any) {

        const result = new Activity();
        result.id = json.id;
        result.name = json.name;
        result.distance = json.distance;
        result.type = json.sport_type;
        result.encoded_polyline = json.map.summary_polyline;

        result.duration.moving_time = json.moving_time;
        result.duration.elapsed_time = json.elapsed_time;

        result.location.start.lat = json.start_latlng[0];
        result.location.start.lng = json.start_latlng[1];

        result.location.end.lat = json.end_latlng[0];
        result.location.end.lng = json.end_latlng[1];

        result.date = new Date(json.start_date);

        return result;
    }

    style: ActivityStyle = new ActivityStyle();

    setStyle(param: ActivityStyle) {
        this.style.color = param.color;
        this.style.weight = param.weight
    }
}